<template>
  <body>
    <div class="banner-bg">
      <div class="banner-content">
        <v-container>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-img max-height="240" contain class="mgt-12px"
              :src="require('@/assets/image/workshop8.jpg')"
            />
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <div class="white-text heading mgt-12px">
                {{ title }} <br/>
                <div><span>({{ date }})</span></div>
                <br/>
                Earlybird 4,900 บาท (1 - 15 ก.ย. 2562) <br>
                Achieve Member 4,425 บาท
                <div class="text-right">
                  จากราคาเต็ม {{ price }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <v-app class="ct-mw">
      <v-container>
        <v-row>
          <!-- Mobile -->
          <v-col cols="12">
            <v-row justify="center">
              <router-link
                v-if="isSale"
                :to="`/payment/cart/${sku}`"
                class="button hidden-md-and-up ltsp">
                <v-btn color="error"><strong> BUY NOW </strong></v-btn>
              </router-link>
              <v-btn
                v-else
                class="button hidden-md-and-up ltsp"
                disabled
              >
                <strong> SOLD OUT </strong>
              </v-btn>
            </v-row>

          </v-col>
          <v-col cols="12" sm="12" md="4" class="hidden-md-and-up">
            <v-container class="white-bg shadow mt-24px" >
              <br/>
              <v-row dense no-gutters
                v-for="salebox in salebox"
                :key="salebox.id">
                <v-col cols="2">
                  <v-row justify="center">
                    <v-img
                      :src="`${salebox.imgicon}`"
                      max-width="30px">
                    </v-img>
                  </v-row>
                </v-col>
                <v-col v-if="'link' in salebox" class="text-content">
                  <a :href="`${salebox.link}`">
                    {{ salebox.title }}</a>
                </v-col>
                <v-col v-else class="text-content">
                  {{ salebox.title }}
                </v-col>
                <!-- <v-col md="4" class="d-none d-sm-flex"></v-col>
                <v-col cols="2" sm="2" md="4" class="text-xs-center "> -->
                  <!-- <v-icon>{{ salebox.icon }}</v-icon> -->
                  <!-- <v-img
                    :src="`${salebox.imgicon}`"
                    width="30px">
                  </v-img>
                </v-col>
                <v-col cols="10" sm="10" md="4">
                  <div class="text-xs-left pdr-16px">{{ salebox.title }}</div>
                </v-col> -->
              </v-row>
              <br/>
              <v-row dense no-gutters>
                <v-col cols="2">
                  <br/>
                  <v-row justify="center">
                    <v-img
                      :src="require('@/assets/image/icon-14.svg')"
                      max-width="30px">
                    </v-img>
                  </v-row>
                    <!-- <v-icon>fas fa-chalkboard-teacher</v-icon> -->
                </v-col>
                <v-col >
                    <span v-if="preInstructor"><strong>{{preInstructor}}</strong><br/></span>
                    <span v-if="instructor">{{instructor}}<br/></span>
                    <span v-if="department">{{department}}<br/></span>
                    <span v-if="faculty">{{faculty}}<br/></span>
                    <span v-if="university">{{university}}<br/></span><br/>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <!-- About Product  -->
          <v-col cols="12" md="8">
            <v-content class="white-bg mt-24px ">
              <div class="pd-24px text-content">
                จากคอร์ส Persuasive Presentation & Negotiation Pathway
                เราได้จัดทำเวิร์กชอป Storytelling เล่าเรื่องยากให้เข้าใจง่าย
                เพื่อพาผู้เรียนไปสู่อีกขึ้นของการนำเนื้อหาในคอร์สเรียนมาประยุกต์ใช้ผ่านการเล่าเรื่อง
                สื่อสาร และนำเสนอ จากอาจารย์ผู้เชี่ยวชาญด้าน Storytelling
                และเป็นที่ปรึกษาและให้การอบรมแก่บุคลากรในองค์กรชั้นนำมากมาย<br/><br/>

                <strong>Workshop นี้เหมาะกับใคร</strong><br/>
                📌ผู้ที่ต้องการเสริมสร้างบุคลิกและความมั่นใจให้กับตนเอง<br/>
                📌ผู้ที่ต้องการเสริมสร้างทักษะด้านการสื่อสาร ในรูปแบบที่หลากหลาย
                โดยเริ่มจากพื้นฐานด้านการออกเสียงอย่างถูกต้อง รวมถึงการใช้ท่าทาง
                ประกอบการเสนอเพื่อเพิ่มพูนศักยภาพด้านการสื่อสารในที่สาธารณะได้อย่างมีประสิทธิภาพ
                (Public Speaking)<br/>
                📌ผู้ที่ต้องการ Refresh เทคนิคการสื่อสาร เพื่อนำไปใช้ในการทำงาน เช่น
                การใช้ Presentation ประกอบการนำเสนอ และการพูดเพื่อโน้มน้าวใจคนฟัง
                <br/><br/>

                <strong>สิ่งที่ผู้เรียนจะได้เรียนรู้ใน Workshop นี้</strong><br/>
                📌เสริมความมั่นใจ Upskill การเล่าเรื่องและการวิเคราะห์ผู้ฟัง<br/>
                📌การเรียงลำดับความคิดของการเล่าเรื่องเพื่อถ่ายทอด Key Message
                ให้โดนใจผู้ฟัง ผ่านสื่อการนำเสนอที่เหมาะสม<br/>
                📌มิตรภาพดี ๆ จากหลากหลายอาชีพที่จะมาแชร์ประสบการณ์
                และแลกเปลี่ยนเทคนิคการนำเสนอจากการทำงานจริง<br/>
                📌ประสบการณ์การนำเสนอ และ โค้ชชิ่ง
                โดยหนึ่งในกูรูด้านการเล่าเรื่องระดับประเทศ<br/>
                📌ผู้เข้าร่วมกิจกรรมจะได้นำเสนอ รวมถึงได้รับไฟล์วิดีโอนำเสนอของตัวเอง
                (Soft File) และ ได้รับ Feedback จากอาจารย์ประจำชุดวิชาอย่างใกล้ชิด
                และ ทั่วถึง<br/><br/>

                <strong>บรรยายโดย</strong><br/>
                ผศ.ดร. ประภัสสร จันทร์สถิตย์พร<br/>
                อาจารย์ประจำภาควิชาวาทวิทยาและสื่อสารการแสดง
                คณะนิเทศศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย<br/><br/>

                ผู้เป็นกูรูด้าน Storytelling และเป็นอาจารย์ประจำชุดวิชา
                Persuasive Presentation & Negotiation Pathway
                ใน course “Storytelling Structure & Media Design”
                รวมถึงเป็นที่ปรึกษาและให้การอบรมแก่บุคลากรในองค์กรชั้นนำมากมาย<br/><br/>

                โดยผู้เข้าร่วมเวิร์กชอปทุกท่านจะได้รับประกาศนียบัตรเชิงปฎิบัติการจาก
                Achieve<br/><br/>

                <strong>วัน เวลา</strong><br/>
                - วันที่: 5 ตุลาคม 2562<br/>
                - เวลา: 08:30 - 17:00 น.<br/><br/>

                <strong>สถานที่</strong><br/>
                หอประชุมนิเทศศาสตร์ ชั้น 11 อาคารมงกุฎสมมติวงศ์
                คณะนิเทศศาสตร์​ จุฬาลงกรณ์มหาวิทยาลัย<br/><br/>

                Earlybird 4,900 บาท (1 - 15 ก.ย. 2562)<br>
                Achieve Member 4,425 บาท <br>
                จากราคาเต็ม 5,900 บาท
                <br/><br/>

                สอบถามข้อมูลเพิ่มเติม<br/>
                LINE Official Account: <a href="https://line.me/R/ti/p/@achieveplus" target="_blank">@achieveplus</a><br/>
                Facebook: <a href="https://www.facebook.com/Achieve.plus.th" target="_blank">CHULA MOOC Achieve</a><br/><br/>
              </div>
              <!-- <v-row>
                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    :src="require('@/assets/image/workshop-image-9.jpg')"
                  ></v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    :src="require('@/assets/image/workshop-image-10.jpg')">
                  <div class="fill-height bottom-gradient"></div>
                  </v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    :src="require('@/assets/image/workshop-image-11.jpg')">
                  <div class="fill-height bottom-gradient"></div>
                  </v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    :src="require('@/assets/image/workshop-image-12.jpg')">
                  <div class="fill-height bottom-gradient"></div>
                  </v-img>
                </v-col>


              </v-row> -->
            </v-content>
          </v-col>
          <!-- Desktop -->
          <v-col cols="12" md="4" class="hidden-sm-and-down">
            <v-content class="white-bg shadow mt-24px">
              <v-col cols="12">
                <v-row justify="center">
                  <router-link
                    v-if="isSale"
                    :to="`/payment/cart/${sku}`"
                    class="button ltsp">
                    <v-btn color="error"><strong> BUY NOW </strong></v-btn>
                  </router-link>
                  <v-btn
                    v-else
                    class="button ltsp"
                    disabled
                  >
                    <strong> SOLD OUT </strong>
                  </v-btn>
                </v-row>
              </v-col>
              <v-container>
                <br/>
                <v-row dense no-gutters
                  v-for="salebox in salebox"
                  :key="salebox.id">
                  <v-col cols="2">
                    <v-row justify="center">
                      <v-img
                        :src="`${salebox.imgicon}`"
                        max-width="30px">
                      </v-img>
                    </v-row>
                  </v-col>
                  <v-col v-if="'link' in salebox" class="text-content">
                    <a :href="`${salebox.link}`">
                      {{ salebox.title }}</a>
                  </v-col>
                  <v-col v-else class="text-content">
                    {{ salebox.title }}
                  </v-col>
                  <!-- <v-col md="4" class="d-none d-sm-flex"></v-col>
                  <v-col cols="2" sm="2" md="4" class="text-xs-center "> -->
                    <!-- <v-icon>{{ salebox.icon }}</v-icon> -->
                    <!-- <v-img
                      :src="`${salebox.imgicon}`"
                      width="30px">
                    </v-img>
                  </v-col>
                  <v-col cols="10" sm="10" md="4">
                    <div class="text-xs-left pdr-16px">{{ salebox.title }}</div>
                  </v-col> -->
                </v-row>
                <br/>
                <v-row dense no-gutters >
                  <v-col cols="2">
                    <br/>
                    <v-row justify="center">
                      <v-img
                        :src="require('@/assets/image/icon-14.svg')"
                        max-width="30px">
                      </v-img>
                    </v-row>
                      <!-- <v-icon>fas fa-chalkboard-teacher</v-icon> -->
                  </v-col>
                  <v-col class="text-content">
                    <span v-if="preInstructor"><strong>{{preInstructor}}</strong><br/></span>
                    <span v-if="instructor">{{instructor}}<br/></span>
                    <span v-if="department">{{department}}<br/></span>
                    <span v-if="faculty">{{faculty}}<br/></span>
                    <span v-if="university">{{university}}<br/></span><br/>
                  </v-col>
                </v-row>
              </v-container>

            </v-content>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </body>
</template>

<script>
export default {
  name: 'workshopDetail',
  props: ['workshopId'],
  data() {
    return {
      card_text: '',
      sku: 'ws0080008',
      workshop: [
        { title: 'Storytelling เล่าเรื่องยากให้เข้าใจง่าย', date: '05/10/2562' },
      ],
      title: 'Storytelling เล่าเรื่องยากให้เข้าใจง่าย',
      date: '05/10/2562',
      price: '5,900 บาท',
      salebox: [
        {
          title: 'อบรมเชิงปฏิบัติการ (workshop)', icon: 'fas fa-laptop-code', imgicon: require('@/assets/image/icon-06.svg'), id: 1,
        },
        {
          title: 'วันเสาร์ที่ 5 ต.ค. 2562', icon: 'fas fa-calendar-alt', imgicon: require('@/assets/image/icon-09.svg'), id: 2,
        },
        {
          title: '08:00 - 17:30 น.', icon: 'far fa-clock', imgicon: require('@/assets/image/icon-10.svg'), id: 3,
        },
        {
          title: 'หอประชุมนิเทศศาสตร์ ชั้น 11 อาคารมงกุฎสมมติวงศ์ คณะนิเทศศาสตร์​ จุฬาลงกรณ์มหาวิทยาลัย', icon: 'fas fa-map-marker-alt', imgicon: require('@/assets/image/icon-11.svg'), id: 4,
        },
        {
          title: 'ระดับพื้นฐาน (Fundamental level)', icon: 'fas fa-tasks', imgicon: require('@/assets/image/icon-12.svg'), id: 5,
        },
        {
          title: 'Achieve+ Member รับส่วนลด 25%', link: '/member', icon: 'fas fa-star', imgicon: require('@/assets/image/icon-13.svg'), id: 6,
        },
      ],
      isSale: new Date('2019-10-04T09:00:00.000Z') > new Date(),
      preInstructor: 'บรรยายโดย',
      instructor: 'ผศ.ดร. ประภัสสร จันทร์สถิตย์พร',
      department: 'ภาควิชาวาทวิทยาและสื่อสารการแสดง',
      faculty: 'คณะนิเทศศาสตร์',
      university: 'จุฬาลงกรณ์มหาวิทยาลัย',
    };
  },
};
</script>
<style scoped>
.ct-mw{
  max-width: 1100px;
  margin: auto;
}
.banner-bg{
  background-color: #001544;
  /* margin-left: -600px;
  margin-right: -600px; */
}
.banner-content{
  max-width: 1100px;
  margin: auto;
}
.content{
  height: 1500px;
  margin: auto;
  max-width: 1200px;
}
.heading{
  font-size: 1.3125rem;
  font-weight: 600;
  letter-spacing: 2px;
}
.pd-24px{
  margin-left: 31px;
  margin-right: 32px;
}
.pd-12px{
  padding-left: 12px;
}
.pdr-16px{
  padding-left: 24px;
  padding-right: 16px;
}
.white-bg{
  box-shadow: #001544;
  border: #001544;
}
.shadow{
  box-shadow: 2px 2px 10px #dfdfdf;
  border-color: #001544;
}
.mt-24px{
  margin-top: 24px;
}
.mlr-24px{
  margin-left: 24px;
  margin-right: 24px;
}
.v-icon{
  font-size: 16px;
}
.mgt-12px{
  margin-top: 12px;
}
.v-btn__content{
  font-weight: 700;
}
.ltsp{
  font-weight: 700;
  letter-spacing: 2px;
}
.mg{
  margin-bottom: 16px;
}
.text-content{
  font-size: 0.875rem;
}
@media screen and (max-width: 770px){
  .heading{
    font-size: 1.0rem;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 1.4rem;
  }
}
</style>
